<!-- 选择出库商品 -->
<template>
<div :class="themeClass">
	
  <el-dialog v-dialogDrag width="50%"  v-if="showModel" title="选择出库商品" :visible.sync="showModel" :header-cell-style="{'text-align': 'center',fontWeight: '400',color: '#333',}" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false">
	<el-input placeholder="搜索出库商品名称/编号" class="selectinputs" v-model.trim="searchText" @change="getTableList()" style="padding-left: 0;">
	  <i slot="suffix" class="el-input__icon el-icon-search" @click="getTableList()" style="line-height: 30px;"></i>
	</el-input>
	<el-table v-loading="tableLoading" :data="tableData" @selection-change="changeCheckBoxRow" border  :header-cell-style="{'text-align': 'center',fontWeight: '400',color: '#000000',}">
      <el-table-column type="selection" align="center" width="30" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="编号" align="center" >
          <template slot-scope="scope">{{ scope.row.fDeliveryOrderNumber }}</template>
      </el-table-column>
      <el-table-column label="商品名称" align="left" >
          <template slot-scope="scope">{{ scope.row.fGoodsFullName }}</template>
      </el-table-column>
	  <el-table-column label="数量" align="right" >
	      <template slot-scope="scope">{{ scope.row.fScheduleVanAmount }}</template>
	  </el-table-column>
	  <el-table-column label="车牌号" align="left" >
	      <template slot-scope="scope">{{ scope.row.fPlateNumber }}</template>
	  </el-table-column>
    </el-table>
	<div class="flex-row-center-flex-end margin-t-20 margin-b-30">
		<pagination @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :pagesize="pagesize" :total="total"></pagination>
	</div>
    <div slot="footer" class="dialog-footer">
      <el-button class="dia-btn text-center" @click="handleClose">取消</el-button>
      <el-button class="dia-btn text-center confirm-btn" @click="submitForm">确定</el-button>
    </div>
  </el-dialog>
</div>

</template>

<script>
import {
	mapGetters
} from "vuex";
import pagination from '@/components/pagination/pagination.vue';
export default {
	components: {
		pagination
	},
  props: {
    // v-model
    showModel: {
      type: Boolean,
      default: false,
    },
    fBeginTime:{
        type:'',
        default:''
    },
	fEndTime:{
	    type:'',
	    default:''
	},
	fApplyType:{
		type:Number,
		default:0
	}
  },
	computed: {
		...mapGetters(["getThemeName"]),
		themeClass() {
			return `theme-${this.getThemeName}`;
		},
	},
  watch:{
    showModel(val){
      if(val==true){
        this.getTableList()
      }
    }
  },
  data() {
    return {
	  pagesize:100, 
	  currentPage: 1,
	  total:0,
      tableLoading: true, 
	  tableData: [],
      checkData: [], 
	  searchText:''
    };
  },
  mounted() {
    // this.getTableList()
  },
  methods: {
	  handleSizeChange(val){
	  },
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getTableList();
		},
    submitForm() {
        if (this.checkData.length ==0) {
          this.tipsInfo('请选择议价出库商品');
          return;
        }
		this.$emit("chooseData", this.checkData);
		this.handleClose();
    },
    // 弹窗关闭事件
    handleClose() {
      this.$emit("change", !this.showModel);
    },
    // 表格数据
    getTableList() {
		console.log(this.currentPage);
      this.tableLoading = true;
	  this.ApiRequestPostNOMess(
	    "api/mall/ebsale/check-list-apply-record/get-ware-numbers", {
			 "filter": this.searchText,
			  "fBeginTime":this.fBeginTime,
			  "fEndTime":this.fEndTime,
			  "skipCount": (this.currentPage - 1) * this.pagesize,
			  "maxResultCount": this.pagesize, 
			  "filter": this.searchText,
			  fApplyType:this.fApplyType
	    }
		).then(
			(result) => {
				console.log(result.obj.totalCount);
			 this.tableData = result.obj.items;
			 this.total = result.obj.totalCount;
			 this.tableLoading=false
			},
			(rej) => {
			  this.tableLoading = false;
			}
		  );
    },
    // 行复选框选中事件
    changeCheckBoxRow(selection) {
      this.checkData = selection;
    },
   
  },
};
</script>

<style lang="scss" scoped>
.el-table_2_column_8{
  padding-left: 10px;
}
.selectinputs input{
	padding-left: 0px !important;
}
.el-table-column--selection{
  ::v-deep .cell{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.el-input__inner{
	padding-left: 0px !important;
}
::v-deep td:first-child{
  padding-left: 0 !important;
}
::v-deep .el-table--border{
  padding-left: 0 !important;
}
::v-deep .el-table--border td:first-child .cell{
  padding-left: 0 !important;
}
::v-deep .el-table-column--selection .cell {
    padding-left: 0;
    padding-right: 0;
}
::v-deep .el-table .cell.el-tooltip {
    min-width: 30px;
}
::v-deep td:first-child{
  padding-left: 0 !important;
}
::v-deep .el-table--border th:first-child .cell{
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-table th>.cell{
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-input__inner{
  // padding-left: 30px !important;
}
::v-deep .el-dialog{
    width:600px;
}
::v-deep .el-dialog__body{
  padding: 0 20px;
}
::v-deep .el-table{
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  margin: 13px 0;
}
.w490{
  width: 490px;
}
.dia-btn{
  width: 60px;
  line-height: 30px;
  height: 30px;
}
.el-button{
  padding: 0;
  font-size: 12px;
}
.confirm-btn {
		@include themify($themes) {
			background-color: themed("themes_color");
			color: themed("text_color_white");
		}
}
</style>

<template>
	<!-- 新增检验单 -->
	<div :class="themeClass" class="contBox margin-t-10 bg-fff">
		<div class="newPersonbtn-title font-size18 font-weight700 margin-t-4 padding-b-13 font-color-000">检验单申请</div>
		<div class="newPersonbtn-Box">
			<el-form :model="addForm" ref="addForm" label-width="130px" class="demo-ruleForm">
				<div class="text-center font-size16 margin-b-25">{{pageTitle}}</div>

				<el-form-item label="发货日期" prop="fDeliveryData" class="fKeepDate">
					<el-date-picker v-model="formmodel.fDeliveryData" type="date" :disabled="true" placeholder="请选择发货日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="申请类型" prop="fApplyType">
				    <el-radio-group v-model="formmodel.fApplyType">
					  <el-radio  v-for="(item, index) in listApplyType" :key="index" :label="item.typeID"
					  :disabled="item.typeID != formmodel.fApplyType">
					  		{{item.name}}
					  </el-radio>
				    </el-radio-group>
				  </el-form-item>
				<el-form-item label="商品全称" prop="fGoodsFullName">
					<el-input v-model="formmodel.fGoodsFullName" placeholder="请选择商品" :disabled="true">
						<el-button slot="append" @click="selectG" :disabled="true">选择商品</el-button>
					</el-input>
				</el-form-item>
				<el-form-item label="出厂编号" prop="fWareNumber">
					<el-input v-model="formmodel.fWareNumber" placeholder="请输入出厂编号" :disabled="true"></el-input>
				</el-form-item>
				<el-form-item label="实发数量" prop="fActualAmount">
					<el-input v-model="formmodel.fActualAmount" placeholder="请输入实发数量" :disabled="true">
						<template slot="append">吨</template>
					</el-input>
				</el-form-item>
				<el-form-item label="车牌号" prop="fPlateNumber">
					<el-input v-model="addForm.fPlateNumber" placeholder="请输入车牌号" :disabled="true">
					</el-input>
				</el-form-item>
				<el-form-item label="申请数量">
					<el-input v-model="addForm.fAmount" @input="setOnlyMoney"></el-input>
				</el-form-item>
				<el-form-item label="工程名称" prop="fqCustomerName">
					<el-input v-model="addForm.fqCustomerName"></el-input>
				</el-form-item>
				<div style="text-align: center;">
					<el-button :loading="addStatus" class="comBtn font-color-fff bg-theme text-center border-theme radius2" @click="save()">保存</el-button>
					<el-button :loading="subStatus" class="comBtn font-color-fff bg-theme text-center border-theme radius2" @click="submitForm()">提交审核</el-button>
					<el-button class="comBtn font-color-fff text-center border-theme radius2 bg-fff color-theme margin-r-8" @click="resetForm()">取消</el-button>
				</div>
			</el-form>
			<div class="bg-box"></div>
		</div>
		<addGoodsDialog :showModel="dialogVisible" @change="changeModel" @chooseData="handleAddDialogChange" :fBeginTime="getDate(fBeginTime)"
		 :fEndTime="getDate(fEndTime)"></addGoodsDialog>
	</div>
</template>

<script>
	import addGoodsDialog from '@/views/business/other/addGoodsDialog';
	import {
		mapGetters
	} from 'vuex';
	export default {
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		components: {
			addGoodsDialog
		},
		data() {
			var checkFAmount = (rule, value, callback) => {
				if (this.formmodel.fScheduleVanAmount) {
					if (parseInt(value) > parseInt(this.formmodel.fScheduleVanAmount)) {
						callback(new Error("申请数量需要小于等于实发数量"));
					} else {
						callback();
					}
				} else {
					callback(new Error("请选择出库商品"));
				}

			};
			return {
				listApplyType:[{
					name:"28天",
					typeID:2,
				},{
					name:"3天",
					typeID:1,
				},{
					name:"合格证",
					typeID:3,
				}],
				subStatus: false,
				addStatus: false,
				dialogVisible: false,
				fBeginTime: '',
				fEndTime: '',
				value1: '',
				pageTitle: '编辑检验单',
				addForm: {
					fAmount: "",
					fqCustomerName: ""
				},

				goodsList: false,
				formmodel: {
					fApplyType:2,
				},
				fBillNumber: ''
			}
		},
		mounted() {
			this.getModel();
		},
		methods: {
			setOnlyMoney() {
				this.$nextTick(() => {
					let val = this.addForm.fAmount.toString();
					val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
					val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
					val = val.replace(/^0+\./g, '0.');
					val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
					val = (val.match(/^\d*(\.?\d{0,2})/g)[0]) || ''
					this.addForm.fAmount = val;
				});
			},
			selectG() {
				if (this.fBeginTime != '' && this.fEndTime != '') {
					if (this.getDate(this.fEndTime) < this.getDate(this.fBeginTime)) {
						return this.$message('结束日期不能早于开始日期');
					}
				} else {
					return this.$message('请选择发货日期');
				}
				this.dialogVisible = true;

			},
			getModel() {
				this.ApiRequestPost(
					"/api/mall/ebsale/check-list-apply-record/get", {
						id: this.$route.query.id
					}
				).then(
					(res) => {
						console.log(res);
						console.log('获取数据，获取编辑数据');

						this.formmodel = res.obj;
						this.fBillNumber = res.obj.fBillNumber;
						this.addForm.fAmount = res.obj.fAmount;
						this.addForm.fqCustomerName = res.obj.fqCustomerName;
						this.addForm.fPlateNumber=res.obj.fPlateNumber
					},
					(error) => {}
				);
			},
			handleAddDialogChange(val) {
				// 回传数据
				this.formmodel = val[0]
			},
			changeModel(val) {
				this.dialogVisible = val;
			},
			submitForm() {
				console.log("申请数量数字", this.addForm.fAmount)
				if (this.addForm.fAmount == 0 || this.addForm.fAmount == "") {
					this.$message.info('申请数量不能为0或空')
					return;
				}
				if (this.addForm.fqCustomerName == "") {
					this.$message.info('工程名称不能为空')
					return;
				}
				if (parseInt(this.formmodel.fActualAmount) < parseInt(this.addForm.fAmount)) {
					this.$message.info('申请数量需要小于等于实发数量')
					return;
				}
				this.$refs['addForm'].validate((valid) => {
					if (valid) {
						this.subStatus = true;
						let params = [{
							"fBillID": this.$route.query.id,
							"fDeliveryData": this.getDate(this.formmodel.fDeliveryData),
							"fAmount": this.addForm.fAmount,
							"fShopUnitID": this.formmodel.fShopUnitID,
							"fGoodsID": this.formmodel.fGoodsID,
							"fBillNumber": this.fBillNumber,
							"fqCustomerName": this.addForm.fqCustomerName,
							"fWareNumber": this.formmodel.fWareNumber,
							fPlateNumber: this.addForm.fPlateNumber,
							fApplyType:this.formmodel.fApplyType
						}];
						this.ApiRequestPut(
							'/api/mall/ebsale/check-list-apply-record/submit-auditing', params
						).then(
							(res) => {
								this.subStatus = false;
								this.$router.push({
									path: "/businessme/InspectionOrder",
								});
							},
							(error) => {
								this.subStatus = false;
							}
						);
					} else {
						this.subStatus = false;
					}
				})


			},
			save() {
				if (this.formmodel.fGoodsID == "") {
					return this.$message('请选择商品');
				}
				console.log("申请数量数字", this.addForm.fAmount)
				if (this.addForm.fAmount == 0 || this.addForm.fAmount == "") {
					this.$message.info('申请数量不能为空')
					return;
				}
				if (this.addForm.fqCustomerName == "") {
					this.$message.info('工程名称不能为空')
					return;
				}
				this.$refs['addForm'].validate((valid) => {
					if (valid) {
						this.addStatus = true;
						let params = {
							"fBillID": this.$route.query.id,
							"fDeliveryData": this.getTime(this.formmodel.fDeliveryData),
							"fAmount": this.addForm.fAmount,
							"fShopUnitID": this.formmodel.fCompanyID,
							"fGoodsID": this.formmodel.fGoodsID,
							"fBillNumber": this.fBillNumber,
							"fqCustomerName": this.addForm.fqCustomerName,
							"frv": this.formmodel.frv,
							"fWareNumber": this.formmodel.fWareNumber,
							"rowStatus": 1,
							fPlateNumber:this.addForm.fPlateNumber,
							fApplyType:this.formmodel.fApplyType
						};
						this.ApiRequestPut(
							'/api/mall/ebsale/check-list-apply-record/update', params
						).then(
							(res) => {
								this.addStatus = false;
								this.$router.push({
									path: "/businessme/InspectionOrder",
								});
							},
							(error) => {
								this.addStatus = false;
							}
						);
					} else {
						this.addStatus = false;
					}

				});


			},
			resetForm() {
				this.$router.push({
					path: "/businessme/InspectionOrder",
				});
			}
		}
	}
</script>
<style lang="scss" scoped="scoped">
	/deep/.el-form-item__content {
		height: 30px !important;
		line-height: 32px;
	}

	.bagm {
		@include themify($themes) {
			background-color: themed("themes_color");
		}

		color:#fff;
	}

	.btncl {
		height: 28px;
		line-height: 28px;
		color: #fff;

		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.inputBor {
		// height: 30px;
		display: flex;
		border: 1px solid #f2f2f2;

		/deep/.el-input__inner {
			border: 0 !important;
		}

		span {
			line-height: 30px;
		}
	}

	.bg-theme {
		@include themify($themes) {
			background-color: themed("themes_color");
		}
	}

	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.el-form::v-deep {
		.el-form-item__label {
			height: 32px;
			line-height: 32px;
		}
	}

	.contBox {
		width: 83%;
	}

	.demo-ruleForm {
		width: 462px;
		margin: 0px auto;
		position: relative;
		z-index: 1;
	}

	.newPersonbtn-Box {

		.el-date-editor.el-input,
		.el-date-editor.el-input__inner {
			width: 100%;
		}

		.el-date-editor .el-range-input,
		.el-date-editor .el-range-separator {
			margin-top: -13px;
			color: #C0C4CC !important;
		}
	}

	.bg-box {
		position: relative;
		z-index: 0;
		width: 100%;
		height: 230px;
		background: url(../../../assets/imgs/u305.png) no-repeat;
		background-size: 911px 310px;
		background-position: right bottom;
		-webkit-filter: blur(1px);
		margin-top: -105px;
		opacity: 25%;
	}

	.newPersonbtn-title {
		// width: 73px;
		height: 22px;
		padding: 6px 0px;
	}

	.fKeepDate::v-deep {
		.el-input__icon {
			line-height: 0;
		}
	}

	::v-deep .el-input__inner,
	.el-select {
		width: 100%;
		height: 32px;
		line-height: 32px;
	}

	/* 按钮 */
	.comBtn {
		width: 130px;
		height: 38px;
		color: #fff;
		cursor: pointer;
		display: inline-block;
		margin-top: 20px;
		margin-right: 10px;
	}
</style>
